(function ($) {
  Drupal.behaviors.utilityNavMobV1 = {
    attach: function (context) {
      var $content = $('.js-utility-nav-mob__more', context);
      var $moreLink = $content.find('.js-utility-nav-mob__more-link');

      $moreLink.on({
        click: function () {
          var $item = $(this).parents('.js-utility-nav-mob__more').first();

          if ($item.hasClass('utility-nav-mob__more')) {
            $('body').toggleClass('utility-nav-active');
          } else {
            $('body').removeClass('utility-nav-active');
          }
        }
      });
    }
  };
})(jQuery);
